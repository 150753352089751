@charset "UTF-8";
@media print {
  /* line 9, workfiles/project/css/print.scss */
  blockquote, pre, code, figure, hr, ol, a {
    page-break-inside: avoid; }
  /* line 13, workfiles/project/css/print.scss */
  h2, h3, h4, p, a {
    orphans: 3;
    widows: 3; }
  /* line 18, workfiles/project/css/print.scss */
  h1, h2, h3, h4, h5, h6 {
    page-break-inside: avoid; }
  /* line 22, workfiles/project/css/print.scss */
  img {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid; }
  /* line 28, workfiles/project/css/print.scss */
  form {
    display: none !important; }
  /* line 32, workfiles/project/css/print.scss */
  a {
    page-break-inside: avoid; }
  /* line 35, workfiles/project/css/print.scss */
  blockquote {
    page-break-inside: avoid; }
  /* line 40, workfiles/project/css/print.scss */
  img {
    page-break-inside: avoid;
    page-break-after: avoid; }
  /* line 45, workfiles/project/css/print.scss */
  pre {
    page-break-inside: avoid; }
  /* line 48, workfiles/project/css/print.scss */
  hr + ul {
    page-break-before: auto; }
  /* line 51, workfiles/project/css/print.scss */
  table {
    width: 100%;
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: auto; }
    /* line 56, workfiles/project/css/print.scss */
    table tr {
      page-break-inside: avoid; }
  /* line 62, workfiles/project/css/print.scss */
  body {
    font: Georgia , "Times New Roman" , Times , serif;
    line-height: 1.3;
    font-size: 12px;
    background: #fff !important;
    color: #000;
    width: 100%;
    padding-top: 100px;
    margin-bottom: 0; }
  /* line 73, workfiles/project/css/print.scss */
  h1 {
    font-size: 33px !important; }
  /* line 75, workfiles/project/css/print.scss */
  h2 {
    margin-top: 28px; }
  /* line 82, workfiles/project/css/print.scss */
  a:link,
  a:visited,
  a {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important; }
    /* line 87, workfiles/project/css/print.scss */
    a:link:after, a:link[href^="http://"]:after, a:link[href^="https://"]:after, a:link[href^="ftp://"]:after,
    a:visited:after,
    a:visited[href^="http://"]:after,
    a:visited[href^="https://"]:after,
    a:visited[href^="ftp://"]:after,
    a:after,
    a[href^="http://"]:after,
    a[href^="https://"]:after,
    a[href^="ftp://"]:after {
      color: #555;
      content: none;
      font-size: 80%; }
  /* line 99, workfiles/project/css/print.scss */
  header a:after,
  footer a:after,
  div.carousel a:after,
  .main-container > .row > .col-md-9 table a:after {
    content: none !important; }
  /* line 106, workfiles/project/css/print.scss */
  .main-wrapper > .container {
    width: auto; }
  /* line 111, workfiles/project/css/print.scss */
  header {
    max-width: 100%; }
  /* line 116, workfiles/project/css/print.scss */
  .header-container.menu-fixed {
    position: static !important;
    top: auto !important;
    max-height: 1px; }
    /* line 121, workfiles/project/css/print.scss */
    .header-container.menu-fixed .header-content-wrap {
      margin-top: 0 !important;
      margin: none !important; }
  /* line 127, workfiles/project/css/print.scss */
  .header-container .header-top {
    padding-bottom: 0; }
    /* line 130, workfiles/project/css/print.scss */
    .header-container .header-top .header-contact-phones {
      padding: 0;
      margin-bottom: 20px; }
      /* line 133, workfiles/project/css/print.scss */
      .header-container .header-top .header-contact-phones .contact-phones-first {
        margin-bottom: 0; }
      /* line 136, workfiles/project/css/print.scss */
      .header-container .header-top .header-contact-phones li {
        width: 19%; }
      /* line 139, workfiles/project/css/print.scss */
      .header-container .header-top .header-contact-phones:after {
        display: block;
        clear: both;
        float: none;
        content: ''; }
    /* line 146, workfiles/project/css/print.scss */
    .header-container .header-top > div.clearfix.visible-xs,
    .header-container .header-top > .container {
      display: none; }
    /* line 151, workfiles/project/css/print.scss */
    .header-container .header-top .header-main-content .container {
      width: auto;
      max-width: 100%; }
      /* line 154, workfiles/project/css/print.scss */
      .header-container .header-top .header-main-content .container .bvs-wrapper {
        position: absolute;
        right: 0;
        top: 15px !important;
        max-width: 35%; }
    /* line 164, workfiles/project/css/print.scss */
    .header-container .header-top .header-main-content .row > div.logo-wrapper {
      position: absolute;
      left: 0;
      top: 30px !important;
      max-width: 35%; }
      /* line 169, workfiles/project/css/print.scss */
      .header-container .header-top .header-main-content .row > div.logo-wrapper .navbar-brand.logo {
        margin-top: 0; }
    /* line 173, workfiles/project/css/print.scss */
    .header-container .header-top .header-main-content .row > div {
      float: none !important; }
    /* line 177, workfiles/project/css/print.scss */
    .header-container .header-top .header-main-content .row > .col-md-6 {
      position: absolute;
      top: 0;
      right: 0; }
      /* line 181, workfiles/project/css/print.scss */
      .header-container .header-top .header-main-content .row > .col-md-6 .navbar-search {
        min-width: auto !important;
        min-height: auto !important; }
        /* line 184, workfiles/project/css/print.scss */
        .header-container .header-top .header-main-content .row > .col-md-6 .navbar-search.navbar-left {
          float: none !important; }
  /* line 192, workfiles/project/css/print.scss */
  .header-container nav.navbar.navbar-default.navbar-main {
    display: none; }
  /* line 197, workfiles/project/css/print.scss */
  .header-content-wrap {
    margin-top: 0 !important; }
  /* line 205, workfiles/project/css/print.scss */
  header .header-image-wrapper {
    min-height: 180px; }
  /* line 208, workfiles/project/css/print.scss */
  header .header-content .header-image-wrapper .background-holder {
    background: none !important;
    position: static;
    bottom: auto; }
  /* line 213, workfiles/project/css/print.scss */
  header .header-content .header-image-wrapper .page-header {
    background: none !important; }
    /* line 217, workfiles/project/css/print.scss */
    header .header-content .header-image-wrapper .page-header .page-subtitle {
      position: relative; }
    /* line 220, workfiles/project/css/print.scss */
    header .header-content .header-image-wrapper .page-header h1 {
      max-width: auto;
      text-shadow: none; }
  /* line 228, workfiles/project/css/print.scss */
  header .header-image-wrapper img.visible-xs {
    display: none !important; }
  /* line 231, workfiles/project/css/print.scss */
  header .header-image-wrapper img.hidden-xs {
    display: none !important; }
  /* line 236, workfiles/project/css/print.scss */
  header .breadcrumbs-wrapper {
    position: static;
    display: block;
    bottom: auto; }
    /* line 240, workfiles/project/css/print.scss */
    header .breadcrumbs-wrapper .row > div.col-md-3 {
      display: none; }
  /* line 251, workfiles/project/css/print.scss */
  .table-responsive {
    padding-top: 15px;
    padding-bottom: 15px; }
    /* line 254, workfiles/project/css/print.scss */
    .table-responsive td {
      word-wrap: break-word;
      overflow-wrap: break-word; }
  /* line 261, workfiles/project/css/print.scss */
  .image-text-wrapper {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
    /* line 264, workfiles/project/css/print.scss */
    .image-text-wrapper img.img-responsive {
      display: block !important; }
  /* line 268, workfiles/project/css/print.scss */
  .image-text-wrapper a:after,
  .icon-parking:after {
    content: " (" attr(href) ")" !important; }
  /* line 279, workfiles/project/css/print.scss */
  .col-main-content > div.hidden-xs,
  div.mobile-slider-replacer {
    display: none !important; }
  /* line 285, workfiles/project/css/print.scss */
  .homepage-jumbotron {
    padding-top: 0; }
    /* line 287, workfiles/project/css/print.scss */
    .homepage-jumbotron .row > div.col-md-4 {
      vertical-align: top; }
      /* line 288, workfiles/project/css/print.scss */
      .homepage-jumbotron .row > div.col-md-4 .col-md-4 {
        display: none !important; }
      /* line 291, workfiles/project/css/print.scss */
      .homepage-jumbotron .row > div.col-md-4 .col-md-8 {
        display: block !important; }
  /* line 298, workfiles/project/css/print.scss */
  .image-list-wrapper ul li:before {
    color: #000 !important; }
  /* line 302, workfiles/project/css/print.scss */
  .container.homepage-boxes {
    margin-bottom: 0 !important; }
    /* line 304, workfiles/project/css/print.scss */
    .container.homepage-boxes img {
      display: none; }
    /* line 307, workfiles/project/css/print.scss */
    .container.homepage-boxes > .row > div {
      float: none;
      padding-top: 15px;
      width: 100%;
      page-break-inside: avoid; }
  /* line 315, workfiles/project/css/print.scss */
  .homepage-boxes .box-with-image-bckg {
    min-width: auto;
    min-height: auto;
    padding: 0; }
  /* line 320, workfiles/project/css/print.scss */
  .margin-middle-top {
    margin-top: 0; }
  /* line 324, workfiles/project/css/print.scss */
  .box-with-white-text {
    padding: 0;
    height: auto; }
    /* line 327, workfiles/project/css/print.scss */
    .box-with-white-text h2 {
      position: static;
      bottom: auto; }
  /* line 333, workfiles/project/css/print.scss */
  .box-bckg-white-text {
    display: none !important; }
  /* line 337, workfiles/project/css/print.scss */
  .red-box-wrapper {
    min-height: auto; }
    /* line 339, workfiles/project/css/print.scss */
    .red-box-wrapper div[class^='col-'] {
      float: none;
      width: 100% !important; }
    /* line 343, workfiles/project/css/print.scss */
    .red-box-wrapper div.col-md-6.col-sm-4.hidden-xs {
      display: none; }
  /* line 348, workfiles/project/css/print.scss */
  .homepage-boxes .red-box-wrapper {
    min-height: auto;
    max-height: auto;
    width: 100%; }
    /* line 352, workfiles/project/css/print.scss */
    .homepage-boxes .red-box-wrapper > .row > div.hidden-xs {
      display: none; }
  /* line 357, workfiles/project/css/print.scss */
  .red-box-wrapper .pointer-right:after,
  .red-box-wrapper .pointer-left:before {
    display: none; }
  /* line 362, workfiles/project/css/print.scss */
  .contact-box .text-wrapper {
    max-width: auto;
    min-width: 80%;
    padding: 0;
    margin-top: 15px; }
    /* line 367, workfiles/project/css/print.scss */
    .contact-box .text-wrapper h2 {
      margin-bottom: 0; }
    /* line 370, workfiles/project/css/print.scss */
    .contact-box .text-wrapper ul {
      padding: 0; }
  /* line 376, workfiles/project/css/print.scss */
  .red-box-wrapper .text-wrapper {
    padding-left: 0 !important; }
  /* line 379, workfiles/project/css/print.scss */
  .red-box-wrapper .pointer-right .text-wrapper ul,
  .red-box-wrapper .text-wrapper ul {
    margin-left: 0; }
    /* line 382, workfiles/project/css/print.scss */
    .red-box-wrapper .pointer-right .text-wrapper ul li,
    .red-box-wrapper .text-wrapper ul li {
      border-bottom: none; }
  /* line 392, workfiles/project/css/print.scss */
  footer {
    display: none; }
    /* line 394, workfiles/project/css/print.scss */
    footer .footer-top,
    footer .container {
      display: none; }
  /* line 403, workfiles/project/css/print.scss */
  .info-box {
    display: none; }
  /* line 406, workfiles/project/css/print.scss */
  .layout-red .info-box {
    border-top: 0; }
  /* line 409, workfiles/project/css/print.scss */
  .course-detail-location {
    margin-top: 15px;
    page-break-inside: avoid; }
  /* line 413, workfiles/project/css/print.scss */
  .course-detail-location .course-location-headline:before {
    color: #000; }
  /* line 416, workfiles/project/css/print.scss */
  .course-location-contact .row > .col-md-6 {
    display: inline-block !important;
    vertical-align: top;
    width: 49% !important; }
  /* line 426, workfiles/project/css/print.scss */
  .tabs-wrap h3 {
    margin-top: 0;
    margin-bottom: 10px; }
  /* line 430, workfiles/project/css/print.scss */
  .tabs-wrap ul li {
    padding-top: 5px;
    padding-bottom: 5px; }
  /* line 436, workfiles/project/css/print.scss */
  .tab-links.nav-tabs li.active > a,
  .tab-links.nav-tabs li.active > a:hover,
  .tab-links.nav-tabs li.active > a:focus {
    border-color: #000 !important; }
  /* line 446, workfiles/project/css/print.scss */
  .gallery {
    margin-bottom: 30px;
    margin-top: 0; }
    /* line 449, workfiles/project/css/print.scss */
    .gallery .gallery-item {
      max-width: 15%;
      padding: 10px;
      height: auto; }
      /* line 453, workfiles/project/css/print.scss */
      .gallery .gallery-item a:after {
        content: none; }
      /* line 456, workfiles/project/css/print.scss */
      .gallery .gallery-item img {
        display: inline-block !important; }
        /* line 458, workfiles/project/css/print.scss */
        .gallery .gallery-item img.img-responsive {
          display: block !important; }
  /* line 468, workfiles/project/css/print.scss */
  #lightbox {
    background-color: #fff !important;
    padding-top: 30px;
    padding-bottom: 30px;
    border: 2px solid black;
    top: 0; }
  /* line 479, workfiles/project/css/print.scss */
  .main-container {
    padding-top: 0 !important; }
  /* line 483, workfiles/project/css/print.scss */
  .teaser-wrapper {
    display: none !important; }
  /* line 487, workfiles/project/css/print.scss */
  .background-holder {
    position: static !important;
    height: auto !important; }
  /* line 494, workfiles/project/css/print.scss */
  .main-container {
    overflow: hidden; }
  /* line 497, workfiles/project/css/print.scss */
  .main-container .row > div[class^="col-"],
  .col-main-content .row > div[class^="col-"] {
    float: none !important;
    width: 100%; }
    /* line 501, workfiles/project/css/print.scss */
    .main-container .row > div[class^="col-"] img,
    .col-main-content .row > div[class^="col-"] img {
      display: none; }
  /* line 507, workfiles/project/css/print.scss */
  .main-container > .row > .col-md-3 {
    display: none !important; }
  /* line 515, workfiles/project/css/print.scss */
  .readmore-container {
    overflow-y: visible;
    padding: 15px;
    box-model: border-box;
    margin-bottom: 30px;
    border-bottom: 1px dotted #666; }
    /* line 521, workfiles/project/css/print.scss */
    .readmore-container .read-more-wrapper {
      display: none !important; }
    /* line 524, workfiles/project/css/print.scss */
    .readmore-container p {
      margin-bottom: 15px; }
  /* line 528, workfiles/project/css/print.scss */
  .readmore-container article {
    max-height: auto !important;
    height: auto !important; }
  /* line 532, workfiles/project/css/print.scss */
  #testimonalCarousel {
    display: none !important; }
  /* line 536, workfiles/project/css/print.scss */
  .internal-link.icon-map,
  .internal-link.icon-video,
  .icon-mit-dem-auto,
  .icon-zu-fuss,
  .icon-mit-den-ov,
  .icon-mit-dem-fahrrad,
  .Zu Fuss durch Zürich,
  div.image-text-wrapper + p + h4 {
    display: none !important; }
  /* line 551, workfiles/project/css/print.scss */
  a.back-link.read-more {
    display: none !important;
    color: red !important; }
  /* line 559, workfiles/project/css/print.scss */
  #section-news .blog-headline {
    font-weight: bold; }
  /* line 562, workfiles/project/css/print.scss */
  #section-news a:after {
    content: none; }
  /* line 565, workfiles/project/css/print.scss */
  #section-news .blog-image,
  #section-news a.read-more {
    display: none; }
  /* line 569, workfiles/project/css/print.scss */
  #section-news .margin-large-bottom,
  #section-news .margin-middle-bottom {
    margin-bottom: none; }
  /* line 573, workfiles/project/css/print.scss */
  #section-news .col-md-6 {
    margin-bottom: 30px; }
  /* line 580, workfiles/project/css/print.scss */
  .tx-intjobs a:after {
    display: block;
    font-weight: normal;
    word-wrap: break-word;
    font-size: 80%; }
  /* line 586, workfiles/project/css/print.scss */
  .tx-intjobs h3 {
    margin-top: 15px;
    margin-bottom: 15px; }
  /* line 590, workfiles/project/css/print.scss */
  .tx-intjobs .span.text-danger {
    margin-top: 15px;
    margin-bottom: 15px; }
  /* line 598, workfiles/project/css/print.scss */
  #courses-accordion .panel-collapse.collapse {
    display: block !important;
    height: auto !important; }
  /* line 602, workfiles/project/css/print.scss */
  #courses-accordion .panel-title {
    font-weight: bold; }
  /* line 605, workfiles/project/css/print.scss */
  #courses-accordion.list-accordion .panel-default .panel-heading a:before,
  #courses-accordion.list-accordion .panel-default .panel-heading a:hover:before,
  #courses-accordion.list-accordion .panel-default .panel-heading a:focus:before {
    content: none; }
  /* line 610, workfiles/project/css/print.scss */
  #courses-accordion .panel-heading {
    padding-left: 0 !important;
    margin-top: 5px; }
  /* line 614, workfiles/project/css/print.scss */
  #courses-accordion p,
  #courses-accordion p.MsoNormal {
    margin-top: 5px;
    margin-bottom: 5px; }
  /* line 622, workfiles/project/css/print.scss */
  .course-detail-wrapper .tabs-wrap .tab-content .tab-pane .course-detail-location .course-location-headline {
    margin-left: 0;
    margin-bottom: 20px; }
  /* line 626, workfiles/project/css/print.scss */
  .course-detail-wrapper .tabs-wrap .tab-content .tab-pane .course-detail-location .course-location-headline.icon-pin-map:before {
    left: -30px; }
  /* line 632, workfiles/project/css/print.scss */
  .layout-green h3 {
    page-break-before: auto  !important;
    margin-bottom: 0;
    padding-bottom: 0; }
  /* line 638, workfiles/project/css/print.scss */
  .readmore p,
  .readmore-container {
    page-break-inside: initial  !important;
    page-break-before: initial  !important;
    page-break-after: initial  !important; }
  /* line 644, workfiles/project/css/print.scss */
  .course-detail-wrapper ul,
  .course-detail-wrapper li {
    page-break-inside: initial  !important;
    page-break-before: initial  !important;
    page-break-after: initial  !important; }
  /* line 651, workfiles/project/css/print.scss */
  .course-detail-wrapper .readmore-container,
  .course-detail-wrapper h3,
  .course-detail-wrapper p,
  .course-detail-wrapper div,
  .course-detail-wrapper article,
  .course-detail-wrapper h3 + p,
  .course-detail-wrapper ul {
    page-break-inside: auto  !important;
    page-break-before: auto  !important;
    page-break-after: auto  !important; }
  /* line 662, workfiles/project/css/print.scss */
  .readmore-container .readmore {
    max-height: none !important;
    min-height: 0 !important;
    height: auto !important; }
  /* line 668, workfiles/project/css/print.scss */
  .readmore-container hr {
    display: none; }
  /* line 672, workfiles/project/css/print.scss */
  .course-detail-wrapper h3 {
    display: inline; }
  /* line 676, workfiles/project/css/print.scss */
  body, header, div, article, p {
    display: inline-block;
    width: 100%;
    page-break-inside: auto; }
  /* line 682, workfiles/project/css/print.scss */
  .course-detail-wrapper-courses ul li {
    page-break-inside: auto; }
  /* line 686, workfiles/project/css/print.scss */
  .course-detail-wrapper, .readmore-container {
    padding-top: 5px;
    padding-bottom: 5px; } }
