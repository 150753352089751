@media print {


	//
	// Define all page breaks
	//


	blockquote, pre, code, figure, hr, ol, a  {
		page-break-inside: avoid;
	}
	// Orphans and widows
	h2, h3, h4, p, a {
		orphans: 3; // minimum number of lines that must be left at the bottom of a page
		widows: 3; // minimum number of lines that must be left at the top of a page
	}
	// Avoid breaking inside or just after titles
	h1, h2, h3, h4, h5, h6 {
		page-break-inside: avoid;
	}
	// Avoid to break inside an image
	img {
		page-break-before: auto;
		page-break-after: auto;
		page-break-inside: avoid;
	}

	form {
		display: none !important;
	}

	a {
	    page-break-inside : avoid;
	}
	blockquote {
	    page-break-inside : avoid;
	}


	img {
		page-break-inside : avoid;
	    page-break-after : avoid;
	}

	pre {
		page-break-inside : avoid;
	}
	hr + ul {
		page-break-before : auto;
	}
	table {
		width: 100%;
		page-break-before: auto;
		page-break-after: auto;
		page-break-inside : auto;
		tr {
			page-break-inside : avoid;
		}
	}


	body {
		font :  Georgia , "Times New Roman" , Times , serif;
		line-height : 1.3;
		font-size: 12px;
		background : #fff  ! important;
		color : #000;
		width: 100%;
		padding-top: 100px; // header height
		margin-bottom: 0;
	}

	h1 {font-size: 33px !important;}

	h2 {margin-top: 28px;}


	//
	// Anchors
	//

	a:link,
	a:visited,
	a {
		word-wrap:break-word !important;
		overflow-wrap: break-word !important ;
		&:after, &[href^="http://"]:after, &[href^="https://"]:after, &[href^="ftp://"]:after {
			color : #555;
			content: none;
			font-size: 80%;
		}
	}

	// hide anchors link
	header,
	footer,
	div.carousel,
	.main-container > .row > .col-md-9 table {
		a:after {
			content: none !important;
		}
	}


	// reset .container width
	.main-wrapper > .container {
		width: auto;
	}

	// Header
	header {
		max-width: 100%;
	}

	.header-container {
		&.menu-fixed {
			position: static !important;
			top: auto !important;
			// resets .header-content-wrap margin top on scroll
			max-height: 1px;
			.header-content-wrap {
				margin-top: 0 !important;
				margin: none !important;
			}
		}

		.header-top {
			padding-bottom: 0;

			.header-contact-phones {
				padding: 0;
				margin-bottom: 20px;
				.contact-phones-first {
					margin-bottom: 0;
				}
				li {
					width: 19%;
				}
				&:after {
					display: block;
					clear: both;
					float: none;
					content: '';
				}
			}
			& >  div.clearfix.visible-xs,
			& > .container {
				display: none;
			}
			.header-main-content {
				.container {
					width: auto;
					max-width: 100%;
					.bvs-wrapper {// right-logo-wrapper parent
						position: absolute;
						right: 0;
						top: 15px !important;
						max-width: 35%;
					}
				}

			}
			// Reset header on scroll
			.header-main-content .row > div.logo-wrapper { // logo
				position: absolute;
				left: 0;
				top: 30px !important;
				max-width: 35%;
				.navbar-brand.logo {
					margin-top: 0;
				}
			}
			.header-main-content .row > div {
				float: none !important;
			}
			// Header phone
			.header-main-content .row > .col-md-6 {
				position: absolute;
				top: 0;
				right: 0;
				.navbar-search {
				    min-width: auto !important;
				    min-height: auto !important;
				    &.navbar-left {
					    float: none !important;
					}
				}
			}

		}

		nav.navbar.navbar-default.navbar-main { // bottom menu
			display: none;
		}
	}

	.header-content-wrap {
		// header on scroll
		margin-top: 0 !important;
	}

	header {

		// two cols header slider
		.header-image-wrapper {
			min-height: 180px;
		}
		.header-content .header-image-wrapper .background-holder {
			background: none !important;
			position: static;
			bottom: auto;
		}
		.header-content .header-image-wrapper .page-header {
			background: none !important;
			//position: relative;
			//bottom: auto;
			.page-subtitle {
				position: relative;
			}
			h1 {
				max-width: auto;
				text-shadow: none;
			}
		}

		// two cols pages image
		.header-image-wrapper {
			img.visible-xs {
				display: none !important;
			}
			img.hidden-xs {
				display: none !important;
			}
		}
		// two cols breadcrumb
		.breadcrumbs-wrapper {
			position: static;
			display: block;
			bottom: auto;
			.row > div.col-md-3 {
				display: none;
			}
		}

	}




	// table responsive
	.table-responsive {
		padding-top: 15px;
		padding-bottom: 15px;
		td {
			word-wrap:break-word;
			overflow-wrap: break-word;
		}
	}

	// contact page icon and text
	.image-text-wrapper {
		padding-top: 15px !important;
		padding-bottom: 15px !important;
		img.img-responsive {
			display: block !important;
		}
	}
	.image-text-wrapper a:after,
	.icon-parking:after {
			content: " (" attr(href) ")" !important;
	}



	//
	// Homepage
	//

	.col-main-content > div.hidden-xs, // home page - slider carousel
	div.mobile-slider-replacer { // home page -  replace image for mobile
		display: none !important;
	}

	// jumbotron
	.homepage-jumbotron {
		padding-top: 0;
		.row > div.col-md-4 {
			.col-md-4 {
				display: none !important;
			}
			.col-md-8 {
				display: block !important
			}
			vertical-align: top;
		}
	}
	// list bullets color
	.image-list-wrapper ul li:before {
		color: #000 !important;
	}
	//  container 1 & 2
	.container.homepage-boxes {
		margin-bottom: 0 !important;
		img {
			display: none;
		}
		& > .row > div {
			float: none;
			padding-top: 15px;
			width:100%;
			page-break-inside: avoid;
		}
	}

	.homepage-boxes .box-with-image-bckg {
		min-width: auto;
		min-height: auto;
		padding: 0;
	}
	.margin-middle-top {
		margin-top: 0;
	}

	.box-with-white-text {
		padding: 0;
		height: auto;
		h2 {
			position: static;
			bottom: auto;
		}
	}

	.box-bckg-white-text {
		display: none !important;
	}

	.red-box-wrapper {
		min-height: auto;
		& div[class^='col-'] {
			float: none;
			width: 100% !important;
		}
		div.col-md-6.col-sm-4.hidden-xs {
			display: none;
		}
	}

	.homepage-boxes .red-box-wrapper {
		min-height: auto;
		max-height: auto;
		width: 100%;
		& > .row > div.hidden-xs {
			display: none;
		}
	}

	.red-box-wrapper .pointer-right:after,
	.red-box-wrapper .pointer-left:before {
		display: none;
	}

	.contact-box .text-wrapper {
		max-width: auto;
		min-width: 80%;
		padding: 0;
		margin-top: 15px;
		h2 {
			margin-bottom: 0;
		}
		ul {
			padding: 0;
			li {
			}
		}
	}
	.red-box-wrapper .text-wrapper {
		padding-left: 0 !important;
	}
	.red-box-wrapper .pointer-right .text-wrapper ul,
	.red-box-wrapper .text-wrapper ul {
		margin-left: 0;
		li {
			border-bottom: none;
		}
	}

	//
	// footer
	//


	footer {
		display: none;
		.footer-top,
		.container {
			display: none;
		}
	}



	//  'course detail' (88)
	.info-box {
		display: none;
	}
	.layout-red .info-box {
		border-top: 0 ;
	}
	.course-detail-location {
		margin-top: 15px;
		page-break-inside: avoid;
	}
	.course-detail-location .course-location-headline:before {
		color: #000;
	}
	.course-location-contact .row > .col-md-6 {
		display: inline-block !important;
		vertical-align: top;
		width: 49% !important;
	}
	//
	// tabs
	//

	.tabs-wrap {
		h3 {
			margin-top: 0;
			margin-bottom: 10px;
		}
		ul li {
			padding-top: 5px;
			padding-bottom: 5px;
// 			outline: 5px solid violet;
		}
	}
	.tab-links.nav-tabs li.active>a,
	.tab-links.nav-tabs li.active>a:hover,
	.tab-links.nav-tabs li.active>a:focus {
		border-color: #000 !important;
	}

	//
	// gallery
	//

	.gallery {
		margin-bottom: 30px;
		margin-top: 0;
		.gallery-item {
			max-width: 15%;
			padding: 10px;
			height: auto;
			a:after {
				content: none;
			}
			img {
				display: inline-block !important;
				&.img-responsive {
					display: block !important;
				}
			}
		}
	}

	//
	// lightbox
	//
	#lightbox {
		background-color: #fff !important;
		padding-top: 30px;
		padding-bottom: 30px;
		border: 2px solid black;
		top: 0;
// 		height: 100%;
	}



	.main-container {
		padding-top: 0 !important;
	}
	// two cols teaser
	.teaser-wrapper {
		display: none !important;
	}

	.background-holder {
		position: static !important;
		height: auto !important;
	}


	// main cols
	.main-container {
		overflow: hidden;  //IE vertical scroll
	}
	.main-container  .row > div[class^="col-"],
	.col-main-content .row > div[class^="col-"] {
		float: none !important;
		width: 100%;
		img {
			display: none;
		}
	}

	// two cols pages - sidebar left
	.main-container > .row > .col-md-3 {
		display: none !important;
	}

	//
	// readmore
	//

	.readmore-container {
		overflow-y: visible;
		padding: 15px;
		box-model: border-box;
		margin-bottom: 30px;
		border-bottom: 1px dotted #666;
		.read-more-wrapper {
			display: none !important;
		}
		p {
			margin-bottom: 15px;
		}
	}
	.readmore-container article {
		max-height: auto !important;
		height: auto !important;
	}
	#testimonalCarousel {
		display: none !important;
	}
	// contact page remove some links (id=157)
	.internal-link.icon-map,
	.internal-link.icon-video,
	.icon-mit-dem-auto,
	.icon-zu-fuss,
	.icon-mit-den-ov,
	.icon-mit-dem-fahrrad,
	.Zu Fuss durch Zürich,
	div.image-text-wrapper + p + h4 {
		display: none !important;
	}


	//
	// Tabs
	//
	a.back-link.read-more {
		display: none !important;
		color: red !important;
	}
	//
	// News
	//
	#section-news {
		.blog-headline {
			font-weight: bold;
		}
		a:after {
			content: none;
		}
		.blog-image,
		a.read-more {
			display: none;
		}
		.margin-large-bottom,
		.margin-middle-bottom {
			margin-bottom: none;
		}
		.col-md-6 {
			margin-bottom: 30px;
		}
	}

	// stellen
	.tx-intjobs {
		a:after {
			display: block;
			font-weight: normal;
			word-wrap:break-word;
			font-size: 80%;
		}
		h3 {
			margin-top: 15px;
			margin-bottom: 15px;
		}
		.span.text-danger {
			margin-top: 15px;
			margin-bottom: 15px;
		}
	}
	//
	// courses accordeon
	#courses-accordion {
		.panel-collapse.collapse {
			display: block !important;
			height: auto !important;
		}
		.panel-title {
			font-weight: bold;
		}
		&.list-accordion .panel-default .panel-heading a:before,
		&.list-accordion .panel-default .panel-heading a:hover:before,
		&.list-accordion .panel-default .panel-heading a:focus:before {
			content: none;
		}
		 .panel-heading {
			padding-left: 0 !important;
			margin-top: 5px;
		}
		p,
		p.MsoNormal {
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}


	.course-detail-wrapper .tabs-wrap .tab-content .tab-pane .course-detail-location .course-location-headline {
		margin-left: 0;
		margin-bottom: 20px;
	}
	.course-detail-wrapper .tabs-wrap .tab-content .tab-pane .course-detail-location .course-location-headline.icon-pin-map:before {
		left: -30px;
	}
	// 2017-06-21


	.layout-green h3 {
		page-break-before: auto  !important;
		margin-bottom: 0;
		padding-bottom: 0;
	}

	.readmore p,
	.readmore-container {
		page-break-inside: initial  !important;
		page-break-before: initial  !important;
		page-break-after: initial  !important;
		}
	.course-detail-wrapper ul,
	.course-detail-wrapper li {
		page-break-inside: initial  !important;
		page-break-before: initial  !important;
		page-break-after: initial  !important;
	}

	.course-detail-wrapper .readmore-container,
	.course-detail-wrapper h3,
	.course-detail-wrapper p,
	.course-detail-wrapper div,
	.course-detail-wrapper article,
	.course-detail-wrapper h3 + p,
	.course-detail-wrapper ul {
		page-break-inside: auto  !important;
		page-break-before: auto  !important;
		page-break-after: auto  !important;
	}
	.readmore-container .readmore {
		max-height: none !important;
		min-height: 0 !important;
		height: auto !important;
	}

	.readmore-container hr {
		display: none;
	}

	 .course-detail-wrapper h3 {
		 display: inline;
	 }

	body, header, div, article, p{
		display: inline-block;
		width: 100%;
		page-break-inside: auto;
	}

	.course-detail-wrapper-courses ul li{
		page-break-inside: auto;
	}

	.course-detail-wrapper, .readmore-container {
		padding-top: 5px;
		padding-bottom: 5px;
	}

}